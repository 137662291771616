/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./fonts/yanone-kaffeesatz-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('./fonts/yanone-kaffeesatz-v16-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/yanone-kaffeesatz-v16-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/yanone-kaffeesatz-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/yanone-kaffeesatz-v16-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/yanone-kaffeesatz-v16-latin-regular.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, 'Yanone Kaffeesatz', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #bce2d1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
